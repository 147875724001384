
import './App.css';


import {Navbar, Hero, Branding, Solutions, Services, About } from './Components/index'


function App() {
  return (
    <div className="App">
     <div className='gradient__bg'>
          <Navbar />
          <Hero />
          <Branding />    
     </div>
     <About />
     <Services />
      <Solutions/>
     
      {/* <Blogcomponent />
      <Adcomponent /> */}
    </div>
  );
}

export default App;
