import React, { useState } from 'react'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../Assets/logo_white.png'
import './navbar.css'

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className='zima__navbar'>
      <div className='zima__navbar-links'>
        <div className='zima__navbar-links_logo'>
          <img src={logo} alt="logo"/>
        </div>
        <div className='zima__navbar-links_container'>
          <p><a href='#home'>Home</a></p>
          <p><a href='#about'>Company</a>
           <span></span>
          </p>
          <p><a href='#leadership'>Leadership</a></p>
          <p><a href='#product'>Our Product</a></p>
        </div>
        <div className='zima__navbar-sign'>
          <button>Say Hello</button>
        </div>
        <div className="zima__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="zima__navbar-menu_container scale-up-center">
          <div className="zima__navbar-menu_container-links">
          <p><a href='#home'>Home</a></p>
          <p><a href='#about'>Company</a>
           <span></span>
          </p>
          <p><a href='#leadership'>Leadership</a></p>
          <p><a href='#product'>Our Product</a></p>
          </div>
          <div className="zima__navbar-menu_container-links-sign">
            <button>Say Hello</button>
          </div>
        </div>
        )}
      </div>
      </div>
    </div>
  ) 
}

export default Navbar