import React from 'react'
import './about.css'
import aboutBg from '../../Assets/Introducing-3.png'


const About = () => {
  return (
    <section className="i-second" >
    <div className="_container">
        <div className="a">
            <h1> about <span>Zimalek <span>.</span></span></h1>
            <p>Zimalek global technologies Limited is an indigenous IT
                 company known as the most rewarded company of the industry.
                  We strive hard to fulfill the expectation of our Valuable clients. 
                  A team of dedicated experts and professional is hired by the company to serve
                   with the best possible solutions to the experts of company. They will prepare a layout
                    and implement the things accordingly to serve you the best possible web-based solutions.
            </p>
            <a href="/about" >— More about Zimalek</a>
        </div>
        <div class="b">
           <img alt='#' src={aboutBg} />
        </div>
    </div>

</section>
  )
}

export default About