import React, { useState }from 'react'
import './hero.css'
import {BsPlayCircleFill } from "react-icons/bs";
import Yotubemodal from '../Youtube-modal/yotubeModal';
import Typewriter from "typewriter-effect";
// import Animate from 'animate.css-react'

const Hero = () => {
    const [isOpen, setIsOpen] = useState(false);
  return (
    <div className='zima__hero'>
        <div className='zima__hero-interaction'>
            <div className='zima__hero-headline'>
            <span className='zima__hero-headline_line'>Home Of Technology and</span>
                <span className='zima__hero-headline_line red_color'>
                <Typewriter onInit={(typewriter)=> { typewriter 
                         .typeString(" Digital Solutions.")
                        .start();
                        }}/>
                </span>
                <div className='live-cursor video home-hero__1 speaking'>
                    <div class="live-cursor__video-wrapper">
                        <img alt="#" src="https://res.cloudinary.com/pitch-software/image/upload/website-assets/home/HeaderCursor_left.jpg"/>
                    </div>
                </div>
                <div className='live-cursor video home-hero__2'>
                    <div class="live-cursor__video-wrapper">
                    <img alt="Person talking in Pitch" src="https://res.cloudinary.com/pitch-software/image/upload/f_auto/website-assets/home/HeaderCursor_right.jpg"/>
                    </div>
                </div>
            </div>
         
        </div>
        <div className='zima__hero-subtext'>
            <h1>We combine the best parts of productivity, design, and
                 presentation in software to help fast-moving teams create beautifully
                  designed platforms swiftly.</h1>
        </div>
        
          <button class="sandwich-video-cta" onClick={() => setIsOpen(true)} >
            <span class="sandwich-video-cta__wrapper">
            <span class="sandwich-video-cta__icon">
            <BsPlayCircleFill size={'30px'}/>
            </span>
            Learn More About Us
            </span>
        </button>
        {isOpen && <Yotubemodal setIsOpen={setIsOpen} />}
        
    </div>
  )
}

export default Hero