import React from 'react'
import './services.css'
import icon1 from '../../Assets/services-icons-4.png'
import icon2 from '../../Assets/services-icons-5.png'
import icon3 from '../../Assets/services-icons-6.png'
// import icon1 from '../../Assets/services-icons-4.png'

const Services = () => {
  return (
    <section _ngcontent-dvb-c19="" className="services_area sa_3">
        <div _ngcontent-dvb-c19="" className="shap_style ss_2 ">
            <span _ngcontent-dvb-c19=""></span>
        </div>
        <div _ngcontent-dvb-c19="" className="container">
            <div _ngcontent-dvb-c19="" className="tittle">
                <h4 _ngcontent-dvb-c19="">We Offer</h4>
                <h2 _ngcontent-dvb-c19="">Creative Studio Development Team &amp; Creative Services</h2>
            </div>
            <div _ngcontent-dvb-c19="" className="row">
                <div _ngcontent-dvb-c19="" className="col-lg-4 col-md-6 " >
                    <div _ngcontent-dvb-c19="" className="services_items">
                        <div _ngcontent-dvb-c19="" className="icons">
                            <img _ngcontent-dvb-c19="" src={icon1} alt=""/>
                            <span _ngcontent-dvb-c19=""></span>
                        </div>
                        <a _ngcontent-dvb-c19="" href="/services">Brand Consultancy</a>
                    </div>
                </div>
                <div _ngcontent-dvb-c19=""  className="col-lg-4 col-md-6 " >
                    <div _ngcontent-dvb-c19="" className="services_items">
                        <div _ngcontent-dvb-c19="" className="icons icons_a">
                            <img _ngcontent-dvb-c19="" src={icon2} alt="" className="icon_2"/>
                            <span _ngcontent-dvb-c19=""></span>
                        </div>
                        <a _ngcontent-dvb-c19="" href="/services">Software Development</a>
                    </div>
                </div>
                <div _ngcontent-dvb-c19=""  className="col-lg-4 col-md-6" >
                    <div _ngcontent-dvb-c19="" className="services_items">
                        <div _ngcontent-dvb-c19="" className="icons">
                            <img _ngcontent-dvb-c19="" src={icon3} alt="" className="icon_3"/>
                            <span _ngcontent-dvb-c19=""></span>
                        </div>
                        <a _ngcontent-dvb-c19="" href="/services">Cyber Security</a>
                    </div>
                </div>
            </div>
            <div _ngcontent-dvb-c19="" className="tittle">
                <a _ngcontent-dvb-c19="" href="/services" className="theme_btn " >
                    Find Out More <i _ngcontent-dvb-c19="" className="fas fa-caret-right"></i>
                </a>
            </div>
        </div>
    </section>
  )
}

export default Services