import React from 'react'
import './youtubeModal.css'
import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed'
import { RiCloseLine } from "react-icons/ri";

const Yotubemodal = ({ setIsOpen }) => {
  return (
    <>
     <div className='darkBG' onClick={() => setIsOpen(false)}></div>
      <div className='centered'>
        <div className='modal'>
            <button className='closeBtn' onClick={() => setIsOpen(false)}>
              <RiCloseLine style={{ marginBottom: "-3px" }} />
            </button>
          <div className='modalContent'>
             <YoutubeEmbed embedId='7bicrCo59Xo' />
          </div>
         </div>
      </div>
     
    </>
  )
}

export default Yotubemodal