import React from 'react'
import './branding.css'

const Branding = () => {
  return (
    <div className="container page-home__teams">
        <div className="container-inner">
            <div className="spacing">
                
                <ul className="team-logos">
                    <li className="team-logos__logo">
                        <span class="unsplash">
                            <img alt="Unsplash logo" data-src="https://res.cloudinary.com/pitch-software/image/upload/website-images/partners/unsplash_wotp32.svg" src="https://res.cloudinary.com/pitch-software/image/upload/website-images/partners/unsplash_wotp32.svg" data-lazyloaded="true"/>
                        </span>
                    </li>
                    <li className="team-logos__logo">
                        <span className="superhuman">
                            <img alt="Superhuman logo" data-src="https://res.cloudinary.com/pitch-software/image/upload/website-images/partners/superhuman_isiyzy.svg" src="https://res.cloudinary.com/pitch-software/image/upload/website-images/partners/superhuman_isiyzy.svg" data-lazyloaded="true"/>
                        </span>
                    </li>
                    <li className="team-logos__logo">
                    <span className="maze">
                        <img alt="Maze logo" data-src="https://res.cloudinary.com/pitch-software/image/upload/v1644231024/website-images/partners/Maze_logo_tiiyui.svg" src="https://res.cloudinary.com/pitch-software/image/upload/v1644231024/website-images/partners/Maze_logo_tiiyui.svg" data-lazyloaded="true"/>
                    </span>
                    </li>
                    <li class="team-logos__logo">
                        <span className="notion">
                        <img alt="Intercom logo" data-src="https://res.cloudinary.com/pitch-software/image/upload/website-images/partners/intercom_nmqamd.svg" src="https://res.cloudinary.com/pitch-software/image/upload/website-images/partners/intercom_nmqamd.svg" data-lazyloaded="true"/>
                        </span>
                    </li>
                </ul>
        </div>
    </div>
</div>
  )
}

export default Branding