import React from 'react'
import './solutions.css'

const Solutions = () => {
  return (
    <div className="information">
      <div className='columns__wrapper'>
        <div className='screen__container'>
          <div className='column__left'>
            <div className='column__header'>
              <h3>Our Solutions.</h3>
              <p>We do strictly believe in serving our clients with the best possible solutions.</p>
            </div>
            <article className='card'>
              <div className='card__header'>
                <p className='subtitle'>ZDevs</p>
                <div className='title'>Digital skill aquisation</div>
                <a href='/zimalek' className='link__btn'>
                  <button className='btn'>
                    <span className='btn__content'>
                      Learn More
                    </span>
                  </button>
                </a>
              </div>
              <div  class="card-image">
                <img alt="card"  src="https://images.prismic.io/yellowcard-website/b78b5831-ce59-4698-9320-94a13669fd11_Mobile+in+hand_bitcoin+buy+%2B+sell+%281%29.png?auto=compress,format"  />
              </div>
            </article>
          </div>
          <div className='column__right'>
            <article className='card1'>
              <div className='card__header'>
              <p className='subtitle'>Zhub</p>
                <div className='title'>Incubation and Accelerator Program</div>
                <a href='/zimalek' className='link__btn'>
                  <button className='btn'>
                    <span className='btn__content'>
                      Learn More
                    </span>
                  </button>
                </a>
              </div>
              <div className='card-image1'>
              <img data-v-498246fa="" src="https://images.prismic.io/yellowcard-website/748204df-c55e-4add-bdc3-8c2ed1a007b8_Lupita+%26+Oduro+with+coffee+%26+mobile+%281%29.png?auto=compress,format" alt="card" />
              </div>
            </article>

            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Solutions